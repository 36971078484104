import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import '@/static/css/common.less';

const ls = window.localStorage;
const whiteRoutes = ['login'];
router.beforeEach((to, from, next) => {
	if (whiteRoutes.indexOf(to.name) == -1) {
		if (ls.getItem("token")) {
			next();
		} else {
			next({
				path: "/login"
			})
		}
	} else {
		next();
	}
})

createApp(App).use(store).use(router).mount('#app')
